import type { GraphQLResolveInfo, GraphQLScalarType, GraphQLScalarTypeConfig } from 'graphql';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = { [_ in K]?: never };
export type Incremental<T> = T | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never };
export type RequireFields<T, K extends keyof T> = Omit<T, K> & { [P in K]-?: NonNullable<T[P]> };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string; }
  String: { input: string; output: string; }
  Boolean: { input: boolean; output: boolean; }
  Int: { input: number; output: number; }
  Float: { input: number; output: number; }
  Date: { input: any; output: any; }
  GraphQLDateTime: { input: any; output: any; }
  JSON: { input: any; output: any; }
};

export type Absence = {
  __typename?: 'Absence';
  actualFtePercentage?: Maybe<Scalars['Float']['output']>;
  actualHours?: Maybe<Scalars['Float']['output']>;
  expectedReturnDate?: Maybe<Scalars['String']['output']>;
  longTerm?: Maybe<Scalars['Boolean']['output']>;
  reason?: Maybe<AbsenceReason>;
  reasonPrettyName?: Maybe<Scalars['String']['output']>;
  startDate?: Maybe<Scalars['String']['output']>;
};

export type AbsenceInput = {
  actualHours?: InputMaybe<Scalars['Float']['input']>;
  expectedReturnDate?: InputMaybe<Scalars['String']['input']>;
  longTerm?: InputMaybe<Scalars['Boolean']['input']>;
  reason?: InputMaybe<AbsenceReason>;
  startDate?: InputMaybe<Scalars['String']['input']>;
};

export enum AbsenceReason {
  GeneralLeave = 'generalLeave',
  ParentalLeave = 'parentalLeave',
  SickLeave = 'sickLeave',
  StudyLeave = 'studyLeave'
}

export type Address = {
  __typename?: 'Address';
  addition?: Maybe<Scalars['String']['output']>;
  city?: Maybe<Scalars['String']['output']>;
  country?: Maybe<Scalars['String']['output']>;
  number?: Maybe<Scalars['String']['output']>;
  postalCode?: Maybe<Scalars['String']['output']>;
  street?: Maybe<Scalars['String']['output']>;
};

export type AddressInput = {
  addition?: InputMaybe<Scalars['String']['input']>;
  city?: InputMaybe<Scalars['String']['input']>;
  country?: InputMaybe<Scalars['String']['input']>;
  number?: InputMaybe<Scalars['String']['input']>;
  postalCode?: InputMaybe<Scalars['String']['input']>;
  street?: InputMaybe<Scalars['String']['input']>;
};

export enum Contactoptions {
  Email = 'email',
  Tel = 'tel'
}

export enum ChangeType {
  Added = 'added',
  Array = 'array',
  Deleted = 'deleted',
  Edited = 'edited'
}

export type ChangedBy = {
  __typename?: 'ChangedBy';
  employee?: Maybe<Employee>;
  entityId?: Maybe<Scalars['ID']['output']>;
  entityType?: Maybe<EntityType>;
  tool?: Maybe<Tool>;
};

export enum City {
  Amsterdam = 'amsterdam',
  Chicago = 'chicago',
  Drammen = 'drammen',
  Gothenburg = 'gothenburg',
  London = 'london',
  Malmo = 'malmo',
  Netherlands = 'netherlands',
  NewYork = 'newYork',
  Oslo = 'oslo',
  Rotterdam = 'rotterdam',
  ShenZhenShi = 'shenZhenShi',
  Stockholm = 'stockholm',
  TheHague = 'theHague'
}

export type Client = {
  __typename?: 'Client';
  _id: Scalars['Int']['output'];
  id: Scalars['Int']['output'];
  name: Scalars['String']['output'];
  projects: Array<Project>;
};

export type Company = {
  __typename?: 'Company';
  address?: Maybe<Address>;
  name?: Maybe<Scalars['String']['output']>;
};

export type CompanyInput = {
  address?: InputMaybe<AddressInput>;
  name?: InputMaybe<Scalars['String']['input']>;
};

export type Contact = {
  __typename?: 'Contact';
  type?: Maybe<Contactoptions>;
  value?: Maybe<Scalars['String']['output']>;
};

export type ContactInput = {
  type?: InputMaybe<Contactoptions>;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type Contract = {
  __typename?: 'Contract';
  endDate?: Maybe<Scalars['String']['output']>;
  hours?: Maybe<Scalars['Int']['output']>;
  startDate?: Maybe<Scalars['String']['output']>;
  url?: Maybe<Scalars['String']['output']>;
};

export type ContractEvent = {
  __typename?: 'ContractEvent';
  description?: Maybe<Scalars['String']['output']>;
  startDate?: Maybe<Scalars['Date']['output']>;
};

export type ContractInput = {
  endDate?: InputMaybe<Scalars['String']['input']>;
  hours?: InputMaybe<Scalars['Int']['input']>;
  startDate?: InputMaybe<Scalars['String']['input']>;
  url?: InputMaybe<Scalars['String']['input']>;
};

export enum Country {
  Denmark = 'denmark',
  Netherlands = 'netherlands',
  Norway = 'norway',
  Sweden = 'sweden',
  Uk = 'uk',
  Usa = 'usa'
}

export enum Currency {
  Dkk = 'DKK',
  Eur = 'EUR',
  Nok = 'NOK',
  Sek = 'SEK',
  Usd = 'USD'
}

export enum Employmentstatus {
  Employed = 'employed',
  NotStarted = 'notStarted',
  Terminated = 'terminated'
}

export enum Employmenttype {
  Consultant = 'consultant',
  FixedTerm = 'fixedTerm',
  Internship = 'internship',
  Permanent = 'permanent',
  Substitute = 'substitute'
}

export type Employee = {
  __typename?: 'Employee';
  _id: Scalars['ID']['output'];
  absence: Absence;
  address: Address;
  age?: Maybe<Scalars['Int']['output']>;
  birthDate?: Maybe<Scalars['String']['output']>;
  contractHours?: Maybe<Scalars['Float']['output']>;
  driveUrl?: Maybe<Scalars['String']['output']>;
  email?: Maybe<Scalars['String']['output']>;
  emailPersonal?: Maybe<Scalars['String']['output']>;
  employeeId?: Maybe<Scalars['Int']['output']>;
  employmentStatus?: Maybe<Employmentstatus>;
  employmentType?: Maybe<Employmenttype>;
  employmentTypePrettyName?: Maybe<Scalars['String']['output']>;
  endDate?: Maybe<Scalars['String']['output']>;
  firstName?: Maybe<Scalars['String']['output']>;
  ftePercentage?: Maybe<Scalars['Float']['output']>;
  fullName: Scalars['String']['output'];
  gender?: Maybe<Gender>;
  iceContacts: Array<Relation>;
  id: Scalars['ID']['output'];
  identity: Identity;
  integrations: Array<Integration>;
  internalAvatar?: Maybe<Scalars['String']['output']>;
  lastName?: Maybe<Scalars['String']['output']>;
  lastWorkingDay?: Maybe<Scalars['String']['output']>;
  mentor?: Maybe<Employee>;
  organisationData: OrganisationData;
  phonePersonal?: Maybe<Scalars['String']['output']>;
  privacy: Privacy;
  probationPeriod?: Maybe<Scalars['Boolean']['output']>;
  probationPeriodEndDate?: Maybe<Scalars['String']['output']>;
  reasonForLeaving?: Maybe<Reasonforleaving>;
  reasonForLeavingPrettyName?: Maybe<Scalars['String']['output']>;
  roles: Array<Scalars['String']['output']>;
  salaryAndBenefits: SalaryAndBenefits;
  scopes: Array<Scalars['String']['output']>;
  sex?: Maybe<Sex>;
  slack?: Maybe<SlackUser>;
  startDate?: Maybe<Scalars['String']['output']>;
  workPermitRequired?: Maybe<Scalars['Boolean']['output']>;
};

export type EmployeeInput = {
  absence?: InputMaybe<AbsenceInput>;
  address?: InputMaybe<AddressInput>;
  birthDate?: InputMaybe<Scalars['String']['input']>;
  contractHours?: InputMaybe<Scalars['Float']['input']>;
  driveUrl?: InputMaybe<Scalars['String']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
  emailPersonal?: InputMaybe<Scalars['String']['input']>;
  employeeId?: InputMaybe<Scalars['Int']['input']>;
  employmentType?: InputMaybe<Employmenttype>;
  endDate?: InputMaybe<Scalars['String']['input']>;
  firstName?: InputMaybe<Scalars['String']['input']>;
  gender?: InputMaybe<Gender>;
  iceContacts?: InputMaybe<Array<InputMaybe<RelationInput>>>;
  identity?: InputMaybe<IdentityInput>;
  lastName?: InputMaybe<Scalars['String']['input']>;
  lastWorkingDay?: InputMaybe<Scalars['String']['input']>;
  mentor?: InputMaybe<Scalars['ID']['input']>;
  organisationData?: InputMaybe<OrganisationDataInput>;
  phonePersonal?: InputMaybe<Scalars['String']['input']>;
  privacy?: InputMaybe<Scalars['JSON']['input']>;
  probationPeriod?: InputMaybe<Scalars['Boolean']['input']>;
  probationPeriodEndDate?: InputMaybe<Scalars['String']['input']>;
  reasonForLeaving?: InputMaybe<Reasonforleaving>;
  roles?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  salaryAndBenefits?: InputMaybe<SalaryAndBenefitsInput>;
  scopes?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  startDate?: InputMaybe<Scalars['String']['input']>;
  workPermitRequired?: InputMaybe<Scalars['Boolean']['input']>;
};

export type EmployeePermission = {
  __typename?: 'EmployeePermission';
  _id?: Maybe<Permission>;
  absence?: Maybe<Permission>;
  address?: Maybe<Permission>;
  age?: Maybe<Permission>;
  birthDate?: Maybe<Permission>;
  contractHours?: Maybe<Permission>;
  driveUrl?: Maybe<Permission>;
  email?: Maybe<Permission>;
  emailPersonal?: Maybe<Permission>;
  employeeId?: Maybe<Permission>;
  employmentStatus?: Maybe<Permission>;
  employmentType?: Maybe<Permission>;
  employmentTypePrettyName?: Maybe<Permission>;
  endDate?: Maybe<Permission>;
  firstName?: Maybe<Permission>;
  ftePercentage?: Maybe<Permission>;
  fullName?: Maybe<Permission>;
  gender?: Maybe<Permission>;
  iceContacts?: Maybe<Permission>;
  id?: Maybe<Permission>;
  identity?: Maybe<Permission>;
  integrations?: Maybe<Permission>;
  internalAvatar?: Maybe<Permission>;
  lastName?: Maybe<Permission>;
  lastWorkingDay?: Maybe<Permission>;
  mentor?: Maybe<Permission>;
  organisationData?: Maybe<Permission>;
  phonePersonal?: Maybe<Permission>;
  privacy?: Maybe<Permission>;
  probationPeriod?: Maybe<Permission>;
  probationPeriodEndDate?: Maybe<Permission>;
  reasonForLeaving?: Maybe<Permission>;
  reasonForLeavingPrettyName?: Maybe<Permission>;
  roles?: Maybe<Permission>;
  salaryAndBenefits?: Maybe<Permission>;
  scopes?: Maybe<Permission>;
  sex?: Maybe<Permission>;
  slack?: Maybe<Permission>;
  startDate?: Maybe<Permission>;
  workPermitRequired?: Maybe<Permission>;
};

export enum EntityType {
  Employee = 'employee',
  Tool = 'tool'
}

export enum EventType {
  CreateEntity = 'createEntity',
  DeleteEntity = 'deleteEntity',
  UpdateEntity = 'updateEntity'
}

export enum Foodpreference {
  Halal = 'halal',
  Kosher = 'kosher',
  None = 'none',
  Other = 'other',
  Vegan = 'vegan',
  Vegetarian = 'vegetarian'
}

export type FieldPermission = {
  __typename?: 'FieldPermission';
  read?: Maybe<Scalars['Boolean']['output']>;
  write?: Maybe<Scalars['Boolean']['output']>;
};

export type FieldReadPermission = {
  __typename?: 'FieldReadPermission';
  read?: Maybe<Scalars['Boolean']['output']>;
};

export enum Gender {
  Female = 'female',
  Male = 'male',
  NonBinary = 'nonBinary',
  NotDisclosed = 'notDisclosed'
}

export enum Idtype {
  DriversLicence = 'driversLicence',
  Idcard = 'idcard',
  Passpoort = 'passpoort'
}

export type Identity = {
  __typename?: 'Identity';
  identifier?: Maybe<Scalars['String']['output']>;
  officialFirstName?: Maybe<Scalars['String']['output']>;
  officialLastName?: Maybe<Scalars['String']['output']>;
  sex?: Maybe<Sex>;
};

export type IdentityInput = {
  identifier?: InputMaybe<Scalars['String']['input']>;
  officialFirstName?: InputMaybe<Scalars['String']['input']>;
  officialLastName?: InputMaybe<Scalars['String']['input']>;
  sex?: InputMaybe<Sex>;
};

export type Integration = {
  __typename?: 'Integration';
  id: Scalars['String']['output'];
  type: Scalars['String']['output'];
};

export enum Jobrole {
  Advisory = 'advisory',
  Analyst = 'analyst',
  Associate = 'associate',
  Consultant = 'consultant',
  Expert = 'expert',
  JuniorAnalyst = 'juniorAnalyst',
  JuniorAssociate = 'juniorAssociate',
  Manager = 'manager',
  Partner = 'partner',
  Principal = 'principal',
  SeniorAdvisory = 'seniorAdvisory',
  SeniorConsultant = 'seniorConsultant',
  SeniorExpert = 'seniorExpert',
  SeniorManager = 'seniorManager',
  SeniorSpecialist = 'seniorSpecialist',
  Specialist = 'specialist'
}

export enum Location {
  Event = 'event',
  Home = 'home',
  Q020 = 'q020',
  Q070 = 'q070'
}

export enum LegalEntity {
  AboveAgencyAb = 'aboveAgencyAb',
  AlexanderReklamebyraAs = 'alexanderReklamebyraAs',
  ArielGrowthAgencyAb = 'arielGrowthAgencyAb',
  BlckCommunication = 'blckCommunication',
  ConversionistaAb = 'conversionistaAb',
  ConversionistaAs = 'conversionistaAs',
  CupoleAb = 'cupoleAb',
  CuramandoAb = 'curamandoAb',
  CuramandoAs = 'curamandoAs',
  CuramandoNl = 'curamandoNl',
  CuriousMind = 'curiousMind',
  EidraBeneluxBv = 'eidraBeneluxBv',
  EidraConsultingGroupAb = 'eidraConsultingGroupAb',
  EidraConsultingGroupAs = 'eidraConsultingGroupAs',
  EidraConsultingNl = 'eidraConsultingNl',
  EidraNoGrowthPlatform = 'eidraNoGrowthPlatform',
  EidraSeGrowthPlatform = 'eidraSeGrowthPlatform',
  Fabrique = 'fabrique',
  Goods = 'goods',
  Heydays = 'heydays',
  KeybrokerAb = 'keybrokerAb',
  KhCommsAb = 'khCommsAb',
  Kreationsbyran = 'kreationsbyran',
  KurppaHoskAs = 'kurppaHoskAs',
  KurppaHoskStudioAb = 'kurppaHoskStudioAb',
  LosCoAs = 'losCoAs',
  LosCoDrammenAs = 'losCoDrammenAs',
  MissionAnew = 'missionAnew',
  NamelessTodayAb = 'namelessTodayAb',
  OceansAs = 'oceansAs',
  ProduktionStockholmFilmAb = 'produktionStockholmFilmAb',
  Punchkick = 'punchkick',
  Q42 = 'q42',
  SplendRekryteringAb = 'splendRekryteringAb',
  TbaAs = 'tbaAs',
  UmainAb = 'umainAb',
  UmainAs = 'umainAs',
  UmainX = 'umainX'
}

export type LogEvent = {
  __typename?: 'LogEvent';
  changeType?: Maybe<ChangeType>;
  changedBy: ChangedBy;
  creationDate: Scalars['Float']['output'];
  entityId: Scalars['ID']['output'];
  entityType: EntityType;
  eventType: EventType;
  path?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  trace?: Maybe<Scalars['String']['output']>;
  validFrom: Scalars['String']['output'];
  value?: Maybe<Scalars['JSON']['output']>;
};

export type LogEventCursor = {
  __typename?: 'LogEventCursor';
  id?: Maybe<Scalars['ID']['output']>;
  validFrom?: Maybe<Scalars['String']['output']>;
};

export type LogEventCursorInput = {
  id?: InputMaybe<Scalars['ID']['input']>;
  validFrom?: InputMaybe<Scalars['String']['input']>;
};

export type LogEventMeta = {
  __typename?: 'LogEventMeta';
  cursor?: Maybe<LogEventCursor>;
  hasMore: Scalars['Boolean']['output'];
};

export type Money = {
  __typename?: 'Money';
  amount?: Maybe<Scalars['Float']['output']>;
  currency?: Maybe<Currency>;
};

export type MoneyInput = {
  amount?: InputMaybe<Scalars['Float']['input']>;
  currency?: InputMaybe<Currency>;
};

export type Mutation = {
  __typename?: 'Mutation';
  createEmployee?: Maybe<Employee>;
  createTrigger?: Maybe<TriggerSettings>;
  deleteTrigger?: Maybe<Scalars['Boolean']['output']>;
  triggerAllEmployeesHook?: Maybe<Scalars['Boolean']['output']>;
  triggerEmployeeHook?: Maybe<Employee>;
  updateEmployee?: Maybe<Employee>;
  updateTool?: Maybe<Tool>;
  updateTrigger?: Maybe<TriggerSettings>;
};


export type MutationCreateEmployeeArgs = {
  input: EmployeeInput;
};


export type MutationCreateTriggerArgs = {
  input: TriggerSettingsInput;
};


export type MutationDeleteTriggerArgs = {
  id: Scalars['ID']['input'];
};


export type MutationTriggerAllEmployeesHookArgs = {
  token?: InputMaybe<Scalars['String']['input']>;
};


export type MutationTriggerEmployeeHookArgs = {
  id: Scalars['ID']['input'];
};


export type MutationUpdateEmployeeArgs = {
  id: Scalars['ID']['input'];
  input: EmployeeInput;
  validFrom?: InputMaybe<Scalars['String']['input']>;
};


export type MutationUpdateToolArgs = {
  id: Scalars['ID']['input'];
  input: ToolInput;
};


export type MutationUpdateTriggerArgs = {
  id: Scalars['ID']['input'];
  input: TriggerSettingsInput;
};

export enum Office {
  Q020 = 'q020',
  Q070 = 'q070',
  Qsa = 'qsa'
}

export type OrganisationData = {
  __typename?: 'OrganisationData';
  billable?: Maybe<Scalars['Boolean']['output']>;
  city?: Maybe<City>;
  costCenter?: Maybe<Scalars['String']['output']>;
  country?: Maybe<Country>;
  employedBy?: Maybe<LegalEntity>;
  employedByPrettyName?: Maybe<Scalars['String']['output']>;
  jobRole?: Maybe<Jobrole>;
  legalEntity?: Maybe<LegalEntity>;
  legalEntityPrettyName?: Maybe<Scalars['String']['output']>;
  organisation?: Maybe<Scalars['String']['output']>;
  organisationSettings: OrganisationSettings;
  seniority?: Maybe<Seniority>;
  skillTrack?: Maybe<Scalars['String']['output']>;
  title?: Maybe<Scalars['String']['output']>;
  track?: Maybe<Track>;
  trackPrettyName?: Maybe<Scalars['String']['output']>;
};

export type OrganisationDataInput = {
  billable?: InputMaybe<Scalars['Boolean']['input']>;
  city?: InputMaybe<Scalars['String']['input']>;
  costCenter?: InputMaybe<Scalars['String']['input']>;
  country?: InputMaybe<Scalars['String']['input']>;
  employedBy?: InputMaybe<LegalEntity>;
  jobRole?: InputMaybe<Jobrole>;
  legalEntity?: InputMaybe<LegalEntity>;
  organisation?: InputMaybe<Scalars['String']['input']>;
  seniority?: InputMaybe<Seniority>;
  skillTrack?: InputMaybe<Scalars['String']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
  track?: InputMaybe<Track>;
};

export type OrganisationSettings = {
  __typename?: 'OrganisationSettings';
  companyCode: Scalars['Int']['output'];
  fteHours: Scalars['Float']['output'];
};

export type PaginatedLogEvents = {
  __typename?: 'PaginatedLogEvents';
  data: Array<LogEvent>;
  meta: LogEventMeta;
};

export type Permission = {
  __typename?: 'Permission';
  read?: Maybe<Scalars['Boolean']['output']>;
  write?: Maybe<Scalars['Boolean']['output']>;
};

export type Permissions = {
  __typename?: 'Permissions';
  employee?: Maybe<EmployeePermission>;
  tool?: Maybe<ToolPermission>;
};


export type PermissionsEmployeeArgs = {
  id?: InputMaybe<Scalars['ID']['input']>;
};


export type PermissionsToolArgs = {
  id?: InputMaybe<Scalars['ID']['input']>;
};

export type Privacy = {
  __typename?: 'Privacy';
  employee?: Maybe<PrivacyEmployee>;
  public?: Maybe<PrivacyPublic>;
};

export type PrivacyEmployee = {
  __typename?: 'PrivacyEmployee';
  address?: Maybe<FieldReadPermission>;
  phonePersonal?: Maybe<FieldReadPermission>;
};

export type PrivacyPublic = {
  __typename?: 'PrivacyPublic';
  phonePersonal?: Maybe<FieldReadPermission>;
};

export type Project = {
  __typename?: 'Project';
  _id: Scalars['ID']['output'];
  client: Client;
  createdBy?: Maybe<Scalars['String']['output']>;
  creationDate: Scalars['String']['output'];
  description?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  number: Scalars['Int']['output'];
  responsibleEmployee?: Maybe<Scalars['String']['output']>;
};

export type Query = {
  __typename?: 'Query';
  auditLogEvents: PaginatedLogEvents;
  client?: Maybe<Client>;
  clients?: Maybe<Array<Maybe<Client>>>;
  employee?: Maybe<Employee>;
  employees: Array<Employee>;
  logEvents: Array<Maybe<LogEvent>>;
  permissions?: Maybe<Permissions>;
  possibleSkippedLogEvents: Array<Maybe<LogEvent>>;
  project?: Maybe<Project>;
  projects?: Maybe<Array<Maybe<Project>>>;
  snapshot: Array<Employee>;
  tool?: Maybe<Tool>;
  tools?: Maybe<Array<Maybe<Tool>>>;
  trigger?: Maybe<TriggerSettings>;
  triggerLogs?: Maybe<Array<Maybe<TriggerLog>>>;
  triggers?: Maybe<Array<Maybe<TriggerSettings>>>;
};


export type QueryAuditLogEventsArgs = {
  cursor?: InputMaybe<LogEventCursorInput>;
  limit?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryClientArgs = {
  id?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryEmployeeArgs = {
  email?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
};


export type QueryEmployeesArgs = {
  legalEntities?: InputMaybe<Array<InputMaybe<LegalEntity>>>;
  legalEntity?: InputMaybe<LegalEntity>;
  showFormerEmployees?: InputMaybe<Scalars['Boolean']['input']>;
};


export type QueryLogEventsArgs = {
  entityId?: InputMaybe<Scalars['ID']['input']>;
  entityType?: InputMaybe<EntityType>;
  showOnlyScheduledInTheFuture?: InputMaybe<Scalars['Boolean']['input']>;
};


export type QueryPossibleSkippedLogEventsArgs = {
  date?: InputMaybe<Scalars['String']['input']>;
  entityId?: InputMaybe<Scalars['ID']['input']>;
  entityType?: InputMaybe<EntityType>;
  paths?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};


export type QueryProjectArgs = {
  id?: InputMaybe<Scalars['ID']['input']>;
};


export type QuerySnapshotArgs = {
  date?: InputMaybe<Scalars['String']['input']>;
};


export type QueryToolArgs = {
  id?: InputMaybe<Scalars['ID']['input']>;
};


export type QueryTriggerArgs = {
  id?: InputMaybe<Scalars['ID']['input']>;
};

export enum Reasonforleaving {
  Death = 'death',
  EndOfTemporaryContract = 'endOfTemporaryContract',
  InvoluntaryOther = 'involuntaryOther',
  OwnResignation = 'ownResignation',
  Redundancy = 'redundancy',
  Retired = 'retired'
}

export type Relation = {
  __typename?: 'Relation';
  contacts: Array<Contact>;
  firstName?: Maybe<Scalars['String']['output']>;
  lastName?: Maybe<Scalars['String']['output']>;
  relation?: Maybe<Scalars['String']['output']>;
};

export type RelationInput = {
  contacts?: InputMaybe<Array<InputMaybe<ContactInput>>>;
  firstName?: InputMaybe<Scalars['String']['input']>;
  lastName?: InputMaybe<Scalars['String']['input']>;
  relation?: InputMaybe<Scalars['String']['input']>;
};

export enum Roles {
  /** Administrator */
  Admin = 'admin',
  /** Capability Lead */
  CapabilityLead = 'capabilityLead',
  /** Chief Operating Officer */
  Coo = 'coo',
  /** IT */
  It = 'it',
  /** Office Manager */
  OfficeManager = 'officeManager'
}

export enum Seniority {
  EntryLevel = 'entryLevel',
  Intern = 'intern',
  Level1 = 'level1',
  Level2 = 'level2',
  Level3 = 'level3',
  Level4 = 'level4',
  Level5 = 'level5',
  Level6 = 'level6'
}

export enum Sex {
  Female = 'female',
  Male = 'male',
  NotDisclosed = 'notDisclosed',
  X = 'x'
}

export type SalaryAndBenefits = {
  __typename?: 'SalaryAndBenefits';
  actualSalary: Money;
  basicSalary: Money;
  vacationEntitlement?: Maybe<Scalars['Int']['output']>;
};

export type SalaryAndBenefitsInput = {
  actualSalary?: InputMaybe<MoneyInput>;
  basicSalary?: InputMaybe<MoneyInput>;
  vacationEntitlement?: InputMaybe<Scalars['Int']['input']>;
};

export enum Scopes {
  /** above */
  Above = 'above',
  /** Alexander Reklamebyrå */
  AlexanderReklamebyraAs = 'alexanderReklamebyraAs',
  /** ariel */
  Ariel = 'ariel',
  /** blck */
  Blck = 'blck',
  /** conversionista */
  Conversionista = 'conversionista',
  /** conversionistaCom */
  ConversionistaCom = 'conversionistaCom',
  /** conversionistaNo */
  ConversionistaNo = 'conversionistaNo',
  /** conversionistaSe */
  ConversionistaSe = 'conversionistaSe',
  /** cupoleSE */
  CupoleSe = 'cupoleSE',
  /** curamandoCom */
  CuramandoCom = 'curamandoCom',
  /** Curamando NL */
  CuramandoNl = 'curamandoNl',
  /** curamandoNo */
  CuramandoNo = 'curamandoNo',
  /** curamandoUsa */
  CuramandoUsa = 'curamandoUsa',
  /** curiousmind */
  Curiousmind = 'curiousmind',
  /** Eidra */
  Eidra = 'eidra',
  /** Eidra Benelux */
  EidraBenelux = 'eidraBenelux',
  /** Eidra NL Consulting */
  EidraConsultingNl = 'eidraConsultingNl',
  /** Eidra Norway */
  EidraNorway = 'eidraNorway',
  /** Eidra USA */
  EidraUsa = 'eidraUsa',
  /** Fabrique */
  Fabrique = 'fabrique',
  /** goods */
  Goods = 'goods',
  /** heydays */
  Heydays = 'heydays',
  /** kreationsbyran */
  Kreationsbyran = 'kreationsbyran',
  /** kurppahoskAs */
  KurppaHoskAs = 'kurppaHoskAs',
  /** kurppahosk */
  Kurppahosk = 'kurppahosk',
  /** kurppahoskComms */
  KurppahoskComms = 'kurppahoskComms',
  /** kurppahoskCommsUsa */
  KurppahoskCommsUsa = 'kurppahoskCommsUsa',
  /** kurppahoskUsa */
  KurppahoskUsa = 'kurppahoskUsa',
  /** Los & Co */
  LosCoAs = 'losCoAs',
  /** Los & Co Drammen */
  LosCoDrammenAs = 'losCoDrammenAs',
  /** missionanew */
  Missionanew = 'missionanew',
  /** nameless */
  Nameless = 'nameless',
  /** Oceans */
  OceansAs = 'oceansAs',
  /** Punchkick */
  Punchkick = 'punchkick',
  /** Q42 */
  Q42 = 'q42',
  /** sthlmfilm */
  Sthlmfilm = 'sthlmfilm',
  /** TBA */
  TbaAs = 'tbaAs',
  /** umain */
  Umain = 'umain',
  /** Umain AS */
  UmainAs = 'umainAs',
  /** umainUsa */
  UmainUsa = 'umainUsa'
}

export type SlackActionConfig = {
  __typename?: 'SlackActionConfig';
  recipients: Array<SlackRecipient>;
};

export type SlackActionConfigInput = {
  recipients: Array<SlackRecipientInput>;
};

export type SlackRecipient = {
  __typename?: 'SlackRecipient';
  employeeId: Scalars['String']['output'];
  slackId: Scalars['String']['output'];
};

export type SlackRecipientInput = {
  employeeId: Scalars['String']['input'];
  slackId: Scalars['String']['input'];
};

export type SlackUser = {
  __typename?: 'SlackUser';
  id?: Maybe<Scalars['ID']['output']>;
  image_72?: Maybe<Scalars['String']['output']>;
  image_192?: Maybe<Scalars['String']['output']>;
  username?: Maybe<Scalars['String']['output']>;
};

export enum Track {
  Account = 'account',
  AccountAndProduction = 'accountAndProduction',
  AdvancedAnalytics = 'advancedAnalytics',
  Advisory = 'advisory',
  AdvisoryConsulting = 'advisoryConsulting',
  Analytics = 'analytics',
  BusinessConsulting = 'businessConsulting',
  Commercial = 'commercial',
  Content = 'content',
  ContentAndProduction = 'contentAndProduction',
  Creative = 'creative',
  DataQuality = 'dataQuality',
  Design = 'design',
  DevelopmentAndManagement = 'developmentAndManagement',
  DigitalAnalytics = 'digitalAnalytics',
  DigitalDesign = 'digitalDesign',
  DigitalMedia = 'digitalMedia',
  DigitalX = 'digitalX',
  Engineering = 'engineering',
  ExperienceAndBehavior = 'experienceAndBehavior',
  Experimentation = 'experimentation',
  Expert = 'expert',
  ExpertConsulting = 'expertConsulting',
  GrowthPlatform = 'growthPlatform',
  InsightsAndAnalytics = 'insightsAndAnalytics',
  Management = 'management',
  Marketing = 'marketing',
  Martech = 'martech',
  Mgmt = 'mgmt',
  Paid = 'paid',
  PhysicalDesign = 'physicalDesign',
  Product = 'product',
  ProductConsulting = 'productConsulting',
  Production = 'production',
  ProjectManagement = 'projectManagement',
  Seo = 'seo',
  SeoAndContent = 'seoAndContent',
  Strategy = 'strategy',
  StrategyAndChange = 'strategyAndChange',
  Technology = 'technology',
  Webs = 'webs'
}

export type Tool = {
  __typename?: 'Tool';
  _id: Scalars['ID']['output'];
  github?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  name?: Maybe<Scalars['String']['output']>;
  roles: Array<Scalars['String']['output']>;
  scopes: Array<Scalars['String']['output']>;
  token?: Maybe<Scalars['String']['output']>;
  url?: Maybe<Scalars['String']['output']>;
};

export type ToolInput = {
  github?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  roles?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  scopes?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  token?: InputMaybe<Scalars['String']['input']>;
  url?: InputMaybe<Scalars['String']['input']>;
};

export type ToolPermission = {
  __typename?: 'ToolPermission';
  _id?: Maybe<Permission>;
  github?: Maybe<Permission>;
  id?: Maybe<Permission>;
  name?: Maybe<Permission>;
  roles?: Maybe<Permission>;
  scopes?: Maybe<Permission>;
  token?: Maybe<Permission>;
  url?: Maybe<Permission>;
};

export type TriggerAction = {
  __typename?: 'TriggerAction';
  body: Scalars['String']['output'];
  config: SlackActionConfig;
  type: TriggerActionType;
};

export type TriggerActionInput = {
  body?: InputMaybe<Scalars['String']['input']>;
  config: SlackActionConfigInput;
  type: TriggerActionType;
};

export type TriggerActionPerformedLog = {
  __typename?: 'TriggerActionPerformedLog';
  message: Scalars['String']['output'];
  recipients: Array<Scalars['String']['output']>;
  type?: Maybe<TriggerActionType>;
};

export enum TriggerActionType {
  SendEmail = 'SendEmail',
  SendSlackMessage = 'SendSlackMessage'
}

export type TriggerCriteria = {
  __typename?: 'TriggerCriteria';
  path: Array<Scalars['String']['output']>;
  target?: Maybe<Scalars['String']['output']>;
  type: TriggerCriteriaComparatorType;
};

export enum TriggerCriteriaComparatorType {
  After = 'after',
  Before = 'before',
  ConcernsPath = 'concernsPath',
  Equals = 'equals',
  HasData = 'hasData',
  IsSameDay = 'isSameDay',
  NoData = 'noData'
}

export type TriggerCriteriaInput = {
  path: Array<Scalars['String']['input']>;
  target?: InputMaybe<Scalars['String']['input']>;
  type: TriggerCriteriaComparatorType;
};

export type TriggerInput = {
  __typename?: 'TriggerInput';
  type: TriggerInputType;
};

export type TriggerInputInput = {
  type: TriggerInputType;
};

export enum TriggerInputType {
  Daily = 'daily',
  UserChanged = 'userChanged',
  UserCreated = 'userCreated'
}

export type TriggerLog = {
  __typename?: 'TriggerLog';
  actionsPerformed: Array<TriggerActionPerformedLog>;
  employeeCount: Scalars['Int']['output'];
  timestamp: Scalars['GraphQLDateTime']['output'];
  trigger: TriggerSettings;
};

export type TriggerLogInput = {
  __typename?: 'TriggerLogInput';
  actionsPerformed: Array<TriggerActionPerformedLog>;
  employeeCount: Scalars['Int']['output'];
  timestamp: Scalars['GraphQLDateTime']['output'];
  trigger: Scalars['String']['output'];
};

export type TriggerSettings = {
  __typename?: 'TriggerSettings';
  _id: Scalars['ID']['output'];
  actions: Array<TriggerAction>;
  criteria: Array<TriggerCriteria>;
  description?: Maybe<Scalars['String']['output']>;
  filters: Array<TriggerCriteria>;
  input: TriggerInput;
  name: Scalars['String']['output'];
};

export type TriggerSettingsInput = {
  actions: Array<InputMaybe<TriggerActionInput>>;
  criteria: Array<InputMaybe<TriggerCriteriaInput>>;
  description?: InputMaybe<Scalars['String']['input']>;
  filters: Array<InputMaybe<TriggerCriteriaInput>>;
  input: TriggerInputInput;
  name: Scalars['String']['input'];
};



export type ResolverTypeWrapper<T> = Promise<T> | T;


export type ResolverWithResolve<TResult, TParent, TContext, TArgs> = {
  resolve: ResolverFn<TResult, TParent, TContext, TArgs>;
};
export type Resolver<TResult, TParent = {}, TContext = {}, TArgs = {}> = ResolverFn<TResult, TParent, TContext, TArgs> | ResolverWithResolve<TResult, TParent, TContext, TArgs>;

export type ResolverFn<TResult, TParent, TContext, TArgs> = (
  parent: TParent,
  args: TArgs,
  context: TContext,
  info: GraphQLResolveInfo
) => Promise<TResult> | TResult;

export type SubscriptionSubscribeFn<TResult, TParent, TContext, TArgs> = (
  parent: TParent,
  args: TArgs,
  context: TContext,
  info: GraphQLResolveInfo
) => AsyncIterable<TResult> | Promise<AsyncIterable<TResult>>;

export type SubscriptionResolveFn<TResult, TParent, TContext, TArgs> = (
  parent: TParent,
  args: TArgs,
  context: TContext,
  info: GraphQLResolveInfo
) => TResult | Promise<TResult>;

export interface SubscriptionSubscriberObject<TResult, TKey extends string, TParent, TContext, TArgs> {
  subscribe: SubscriptionSubscribeFn<{ [key in TKey]: TResult }, TParent, TContext, TArgs>;
  resolve?: SubscriptionResolveFn<TResult, { [key in TKey]: TResult }, TContext, TArgs>;
}

export interface SubscriptionResolverObject<TResult, TParent, TContext, TArgs> {
  subscribe: SubscriptionSubscribeFn<any, TParent, TContext, TArgs>;
  resolve: SubscriptionResolveFn<TResult, any, TContext, TArgs>;
}

export type SubscriptionObject<TResult, TKey extends string, TParent, TContext, TArgs> =
  | SubscriptionSubscriberObject<TResult, TKey, TParent, TContext, TArgs>
  | SubscriptionResolverObject<TResult, TParent, TContext, TArgs>;

export type SubscriptionResolver<TResult, TKey extends string, TParent = {}, TContext = {}, TArgs = {}> =
  | ((...args: any[]) => SubscriptionObject<TResult, TKey, TParent, TContext, TArgs>)
  | SubscriptionObject<TResult, TKey, TParent, TContext, TArgs>;

export type TypeResolveFn<TTypes, TParent = {}, TContext = {}> = (
  parent: TParent,
  context: TContext,
  info: GraphQLResolveInfo
) => Maybe<TTypes> | Promise<Maybe<TTypes>>;

export type IsTypeOfResolverFn<T = {}, TContext = {}> = (obj: T, context: TContext, info: GraphQLResolveInfo) => boolean | Promise<boolean>;

export type NextResolverFn<T> = () => Promise<T>;

export type DirectiveResolverFn<TResult = {}, TParent = {}, TContext = {}, TArgs = {}> = (
  next: NextResolverFn<TResult>,
  parent: TParent,
  args: TArgs,
  context: TContext,
  info: GraphQLResolveInfo
) => TResult | Promise<TResult>;



/** Mapping between all available schema types and the resolvers types */
export type ResolversTypes = {
  Absence: ResolverTypeWrapper<Absence>;
  AbsenceInput: AbsenceInput;
  AbsenceReason: AbsenceReason;
  Address: ResolverTypeWrapper<Address>;
  AddressInput: AddressInput;
  Boolean: ResolverTypeWrapper<Scalars['Boolean']['output']>;
  CONTACTOPTIONS: Contactoptions;
  ChangeType: ChangeType;
  ChangedBy: ResolverTypeWrapper<ChangedBy>;
  City: City;
  Client: ResolverTypeWrapper<Client>;
  Company: ResolverTypeWrapper<Company>;
  CompanyInput: CompanyInput;
  Contact: ResolverTypeWrapper<Contact>;
  ContactInput: ContactInput;
  Contract: ResolverTypeWrapper<Contract>;
  ContractEvent: ResolverTypeWrapper<ContractEvent>;
  ContractInput: ContractInput;
  Country: Country;
  Currency: Currency;
  Date: ResolverTypeWrapper<Scalars['Date']['output']>;
  EMPLOYMENTSTATUS: Employmentstatus;
  EMPLOYMENTTYPE: Employmenttype;
  Employee: ResolverTypeWrapper<Employee>;
  EmployeeInput: EmployeeInput;
  EmployeePermission: ResolverTypeWrapper<EmployeePermission>;
  EntityType: EntityType;
  EventType: EventType;
  FOODPREFERENCE: Foodpreference;
  FieldPermission: ResolverTypeWrapper<FieldPermission>;
  FieldReadPermission: ResolverTypeWrapper<FieldReadPermission>;
  Float: ResolverTypeWrapper<Scalars['Float']['output']>;
  GENDER: Gender;
  GraphQLDateTime: ResolverTypeWrapper<Scalars['GraphQLDateTime']['output']>;
  ID: ResolverTypeWrapper<Scalars['ID']['output']>;
  IDTYPE: Idtype;
  Identity: ResolverTypeWrapper<Identity>;
  IdentityInput: IdentityInput;
  Int: ResolverTypeWrapper<Scalars['Int']['output']>;
  Integration: ResolverTypeWrapper<Integration>;
  JOBROLE: Jobrole;
  JSON: ResolverTypeWrapper<Scalars['JSON']['output']>;
  LOCATION: Location;
  LegalEntity: LegalEntity;
  LogEvent: ResolverTypeWrapper<LogEvent>;
  LogEventCursor: ResolverTypeWrapper<LogEventCursor>;
  LogEventCursorInput: LogEventCursorInput;
  LogEventMeta: ResolverTypeWrapper<LogEventMeta>;
  Money: ResolverTypeWrapper<Money>;
  MoneyInput: MoneyInput;
  Mutation: ResolverTypeWrapper<{}>;
  OFFICE: Office;
  OrganisationData: ResolverTypeWrapper<OrganisationData>;
  OrganisationDataInput: OrganisationDataInput;
  OrganisationSettings: ResolverTypeWrapper<OrganisationSettings>;
  PaginatedLogEvents: ResolverTypeWrapper<PaginatedLogEvents>;
  Permission: ResolverTypeWrapper<Permission>;
  Permissions: ResolverTypeWrapper<Permissions>;
  Privacy: ResolverTypeWrapper<Privacy>;
  PrivacyEmployee: ResolverTypeWrapper<PrivacyEmployee>;
  PrivacyPublic: ResolverTypeWrapper<PrivacyPublic>;
  Project: ResolverTypeWrapper<Project>;
  Query: ResolverTypeWrapper<{}>;
  REASONFORLEAVING: Reasonforleaving;
  Relation: ResolverTypeWrapper<Relation>;
  RelationInput: RelationInput;
  Roles: Roles;
  SENIORITY: Seniority;
  SEX: Sex;
  SalaryAndBenefits: ResolverTypeWrapper<SalaryAndBenefits>;
  SalaryAndBenefitsInput: SalaryAndBenefitsInput;
  Scopes: Scopes;
  SlackActionConfig: ResolverTypeWrapper<SlackActionConfig>;
  SlackActionConfigInput: SlackActionConfigInput;
  SlackRecipient: ResolverTypeWrapper<SlackRecipient>;
  SlackRecipientInput: SlackRecipientInput;
  SlackUser: ResolverTypeWrapper<SlackUser>;
  String: ResolverTypeWrapper<Scalars['String']['output']>;
  TRACK: Track;
  Tool: ResolverTypeWrapper<Tool>;
  ToolInput: ToolInput;
  ToolPermission: ResolverTypeWrapper<ToolPermission>;
  TriggerAction: ResolverTypeWrapper<TriggerAction>;
  TriggerActionInput: TriggerActionInput;
  TriggerActionPerformedLog: ResolverTypeWrapper<TriggerActionPerformedLog>;
  TriggerActionType: TriggerActionType;
  TriggerCriteria: ResolverTypeWrapper<TriggerCriteria>;
  TriggerCriteriaComparatorType: TriggerCriteriaComparatorType;
  TriggerCriteriaInput: TriggerCriteriaInput;
  TriggerInput: ResolverTypeWrapper<TriggerInput>;
  TriggerInputInput: TriggerInputInput;
  TriggerInputType: TriggerInputType;
  TriggerLog: ResolverTypeWrapper<TriggerLog>;
  TriggerLogInput: ResolverTypeWrapper<TriggerLogInput>;
  TriggerSettings: ResolverTypeWrapper<TriggerSettings>;
  TriggerSettingsInput: TriggerSettingsInput;
};

/** Mapping between all available schema types and the resolvers parents */
export type ResolversParentTypes = {
  Absence: Absence;
  AbsenceInput: AbsenceInput;
  Address: Address;
  AddressInput: AddressInput;
  Boolean: Scalars['Boolean']['output'];
  ChangedBy: ChangedBy;
  Client: Client;
  Company: Company;
  CompanyInput: CompanyInput;
  Contact: Contact;
  ContactInput: ContactInput;
  Contract: Contract;
  ContractEvent: ContractEvent;
  ContractInput: ContractInput;
  Date: Scalars['Date']['output'];
  Employee: Employee;
  EmployeeInput: EmployeeInput;
  EmployeePermission: EmployeePermission;
  FieldPermission: FieldPermission;
  FieldReadPermission: FieldReadPermission;
  Float: Scalars['Float']['output'];
  GraphQLDateTime: Scalars['GraphQLDateTime']['output'];
  ID: Scalars['ID']['output'];
  Identity: Identity;
  IdentityInput: IdentityInput;
  Int: Scalars['Int']['output'];
  Integration: Integration;
  JSON: Scalars['JSON']['output'];
  LogEvent: LogEvent;
  LogEventCursor: LogEventCursor;
  LogEventCursorInput: LogEventCursorInput;
  LogEventMeta: LogEventMeta;
  Money: Money;
  MoneyInput: MoneyInput;
  Mutation: {};
  OrganisationData: OrganisationData;
  OrganisationDataInput: OrganisationDataInput;
  OrganisationSettings: OrganisationSettings;
  PaginatedLogEvents: PaginatedLogEvents;
  Permission: Permission;
  Permissions: Permissions;
  Privacy: Privacy;
  PrivacyEmployee: PrivacyEmployee;
  PrivacyPublic: PrivacyPublic;
  Project: Project;
  Query: {};
  Relation: Relation;
  RelationInput: RelationInput;
  SalaryAndBenefits: SalaryAndBenefits;
  SalaryAndBenefitsInput: SalaryAndBenefitsInput;
  SlackActionConfig: SlackActionConfig;
  SlackActionConfigInput: SlackActionConfigInput;
  SlackRecipient: SlackRecipient;
  SlackRecipientInput: SlackRecipientInput;
  SlackUser: SlackUser;
  String: Scalars['String']['output'];
  Tool: Tool;
  ToolInput: ToolInput;
  ToolPermission: ToolPermission;
  TriggerAction: TriggerAction;
  TriggerActionInput: TriggerActionInput;
  TriggerActionPerformedLog: TriggerActionPerformedLog;
  TriggerCriteria: TriggerCriteria;
  TriggerCriteriaInput: TriggerCriteriaInput;
  TriggerInput: TriggerInput;
  TriggerInputInput: TriggerInputInput;
  TriggerLog: TriggerLog;
  TriggerLogInput: TriggerLogInput;
  TriggerSettings: TriggerSettings;
  TriggerSettingsInput: TriggerSettingsInput;
};

export type AbsenceResolvers<ContextType = any, ParentType extends ResolversParentTypes['Absence'] = ResolversParentTypes['Absence']> = {
  actualFtePercentage?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  actualHours?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  expectedReturnDate?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  longTerm?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  reason?: Resolver<Maybe<ResolversTypes['AbsenceReason']>, ParentType, ContextType>;
  reasonPrettyName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  startDate?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type AddressResolvers<ContextType = any, ParentType extends ResolversParentTypes['Address'] = ResolversParentTypes['Address']> = {
  addition?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  city?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  country?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  number?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  postalCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  street?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ChangedByResolvers<ContextType = any, ParentType extends ResolversParentTypes['ChangedBy'] = ResolversParentTypes['ChangedBy']> = {
  employee?: Resolver<Maybe<ResolversTypes['Employee']>, ParentType, ContextType>;
  entityId?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  entityType?: Resolver<Maybe<ResolversTypes['EntityType']>, ParentType, ContextType>;
  tool?: Resolver<Maybe<ResolversTypes['Tool']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ClientResolvers<ContextType = any, ParentType extends ResolversParentTypes['Client'] = ResolversParentTypes['Client']> = {
  _id?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  projects?: Resolver<Array<ResolversTypes['Project']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CompanyResolvers<ContextType = any, ParentType extends ResolversParentTypes['Company'] = ResolversParentTypes['Company']> = {
  address?: Resolver<Maybe<ResolversTypes['Address']>, ParentType, ContextType>;
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ContactResolvers<ContextType = any, ParentType extends ResolversParentTypes['Contact'] = ResolversParentTypes['Contact']> = {
  type?: Resolver<Maybe<ResolversTypes['CONTACTOPTIONS']>, ParentType, ContextType>;
  value?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ContractResolvers<ContextType = any, ParentType extends ResolversParentTypes['Contract'] = ResolversParentTypes['Contract']> = {
  endDate?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  hours?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  startDate?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  url?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ContractEventResolvers<ContextType = any, ParentType extends ResolversParentTypes['ContractEvent'] = ResolversParentTypes['ContractEvent']> = {
  description?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  startDate?: Resolver<Maybe<ResolversTypes['Date']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export interface DateScalarConfig extends GraphQLScalarTypeConfig<ResolversTypes['Date'], any> {
  name: 'Date';
}

export type EmployeeResolvers<ContextType = any, ParentType extends ResolversParentTypes['Employee'] = ResolversParentTypes['Employee']> = {
  _id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  absence?: Resolver<ResolversTypes['Absence'], ParentType, ContextType>;
  address?: Resolver<ResolversTypes['Address'], ParentType, ContextType>;
  age?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  birthDate?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  contractHours?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  driveUrl?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  email?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  emailPersonal?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  employeeId?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  employmentStatus?: Resolver<Maybe<ResolversTypes['EMPLOYMENTSTATUS']>, ParentType, ContextType>;
  employmentType?: Resolver<Maybe<ResolversTypes['EMPLOYMENTTYPE']>, ParentType, ContextType>;
  employmentTypePrettyName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  endDate?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  firstName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ftePercentage?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  fullName?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  gender?: Resolver<Maybe<ResolversTypes['GENDER']>, ParentType, ContextType>;
  iceContacts?: Resolver<Array<ResolversTypes['Relation']>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  identity?: Resolver<ResolversTypes['Identity'], ParentType, ContextType>;
  integrations?: Resolver<Array<ResolversTypes['Integration']>, ParentType, ContextType>;
  internalAvatar?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  lastName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  lastWorkingDay?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  mentor?: Resolver<Maybe<ResolversTypes['Employee']>, ParentType, ContextType>;
  organisationData?: Resolver<ResolversTypes['OrganisationData'], ParentType, ContextType>;
  phonePersonal?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  privacy?: Resolver<ResolversTypes['Privacy'], ParentType, ContextType>;
  probationPeriod?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  probationPeriodEndDate?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  reasonForLeaving?: Resolver<Maybe<ResolversTypes['REASONFORLEAVING']>, ParentType, ContextType>;
  reasonForLeavingPrettyName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  roles?: Resolver<Array<ResolversTypes['String']>, ParentType, ContextType>;
  salaryAndBenefits?: Resolver<ResolversTypes['SalaryAndBenefits'], ParentType, ContextType>;
  scopes?: Resolver<Array<ResolversTypes['String']>, ParentType, ContextType>;
  sex?: Resolver<Maybe<ResolversTypes['SEX']>, ParentType, ContextType>;
  slack?: Resolver<Maybe<ResolversTypes['SlackUser']>, ParentType, ContextType>;
  startDate?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  workPermitRequired?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type EmployeePermissionResolvers<ContextType = any, ParentType extends ResolversParentTypes['EmployeePermission'] = ResolversParentTypes['EmployeePermission']> = {
  _id?: Resolver<Maybe<ResolversTypes['Permission']>, ParentType, ContextType>;
  absence?: Resolver<Maybe<ResolversTypes['Permission']>, ParentType, ContextType>;
  address?: Resolver<Maybe<ResolversTypes['Permission']>, ParentType, ContextType>;
  age?: Resolver<Maybe<ResolversTypes['Permission']>, ParentType, ContextType>;
  birthDate?: Resolver<Maybe<ResolversTypes['Permission']>, ParentType, ContextType>;
  contractHours?: Resolver<Maybe<ResolversTypes['Permission']>, ParentType, ContextType>;
  driveUrl?: Resolver<Maybe<ResolversTypes['Permission']>, ParentType, ContextType>;
  email?: Resolver<Maybe<ResolversTypes['Permission']>, ParentType, ContextType>;
  emailPersonal?: Resolver<Maybe<ResolversTypes['Permission']>, ParentType, ContextType>;
  employeeId?: Resolver<Maybe<ResolversTypes['Permission']>, ParentType, ContextType>;
  employmentStatus?: Resolver<Maybe<ResolversTypes['Permission']>, ParentType, ContextType>;
  employmentType?: Resolver<Maybe<ResolversTypes['Permission']>, ParentType, ContextType>;
  employmentTypePrettyName?: Resolver<Maybe<ResolversTypes['Permission']>, ParentType, ContextType>;
  endDate?: Resolver<Maybe<ResolversTypes['Permission']>, ParentType, ContextType>;
  firstName?: Resolver<Maybe<ResolversTypes['Permission']>, ParentType, ContextType>;
  ftePercentage?: Resolver<Maybe<ResolversTypes['Permission']>, ParentType, ContextType>;
  fullName?: Resolver<Maybe<ResolversTypes['Permission']>, ParentType, ContextType>;
  gender?: Resolver<Maybe<ResolversTypes['Permission']>, ParentType, ContextType>;
  iceContacts?: Resolver<Maybe<ResolversTypes['Permission']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['Permission']>, ParentType, ContextType>;
  identity?: Resolver<Maybe<ResolversTypes['Permission']>, ParentType, ContextType>;
  integrations?: Resolver<Maybe<ResolversTypes['Permission']>, ParentType, ContextType>;
  internalAvatar?: Resolver<Maybe<ResolversTypes['Permission']>, ParentType, ContextType>;
  lastName?: Resolver<Maybe<ResolversTypes['Permission']>, ParentType, ContextType>;
  lastWorkingDay?: Resolver<Maybe<ResolversTypes['Permission']>, ParentType, ContextType>;
  mentor?: Resolver<Maybe<ResolversTypes['Permission']>, ParentType, ContextType>;
  organisationData?: Resolver<Maybe<ResolversTypes['Permission']>, ParentType, ContextType>;
  phonePersonal?: Resolver<Maybe<ResolversTypes['Permission']>, ParentType, ContextType>;
  privacy?: Resolver<Maybe<ResolversTypes['Permission']>, ParentType, ContextType>;
  probationPeriod?: Resolver<Maybe<ResolversTypes['Permission']>, ParentType, ContextType>;
  probationPeriodEndDate?: Resolver<Maybe<ResolversTypes['Permission']>, ParentType, ContextType>;
  reasonForLeaving?: Resolver<Maybe<ResolversTypes['Permission']>, ParentType, ContextType>;
  reasonForLeavingPrettyName?: Resolver<Maybe<ResolversTypes['Permission']>, ParentType, ContextType>;
  roles?: Resolver<Maybe<ResolversTypes['Permission']>, ParentType, ContextType>;
  salaryAndBenefits?: Resolver<Maybe<ResolversTypes['Permission']>, ParentType, ContextType>;
  scopes?: Resolver<Maybe<ResolversTypes['Permission']>, ParentType, ContextType>;
  sex?: Resolver<Maybe<ResolversTypes['Permission']>, ParentType, ContextType>;
  slack?: Resolver<Maybe<ResolversTypes['Permission']>, ParentType, ContextType>;
  startDate?: Resolver<Maybe<ResolversTypes['Permission']>, ParentType, ContextType>;
  workPermitRequired?: Resolver<Maybe<ResolversTypes['Permission']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type FieldPermissionResolvers<ContextType = any, ParentType extends ResolversParentTypes['FieldPermission'] = ResolversParentTypes['FieldPermission']> = {
  read?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  write?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type FieldReadPermissionResolvers<ContextType = any, ParentType extends ResolversParentTypes['FieldReadPermission'] = ResolversParentTypes['FieldReadPermission']> = {
  read?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export interface GraphQlDateTimeScalarConfig extends GraphQLScalarTypeConfig<ResolversTypes['GraphQLDateTime'], any> {
  name: 'GraphQLDateTime';
}

export type IdentityResolvers<ContextType = any, ParentType extends ResolversParentTypes['Identity'] = ResolversParentTypes['Identity']> = {
  identifier?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  officialFirstName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  officialLastName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  sex?: Resolver<Maybe<ResolversTypes['SEX']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type IntegrationResolvers<ContextType = any, ParentType extends ResolversParentTypes['Integration'] = ResolversParentTypes['Integration']> = {
  id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  type?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export interface JsonScalarConfig extends GraphQLScalarTypeConfig<ResolversTypes['JSON'], any> {
  name: 'JSON';
}

export type LogEventResolvers<ContextType = any, ParentType extends ResolversParentTypes['LogEvent'] = ResolversParentTypes['LogEvent']> = {
  changeType?: Resolver<Maybe<ResolversTypes['ChangeType']>, ParentType, ContextType>;
  changedBy?: Resolver<ResolversTypes['ChangedBy'], ParentType, ContextType>;
  creationDate?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  entityId?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  entityType?: Resolver<ResolversTypes['EntityType'], ParentType, ContextType>;
  eventType?: Resolver<ResolversTypes['EventType'], ParentType, ContextType>;
  path?: Resolver<Maybe<Array<Maybe<ResolversTypes['String']>>>, ParentType, ContextType>;
  trace?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  validFrom?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  value?: Resolver<Maybe<ResolversTypes['JSON']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type LogEventCursorResolvers<ContextType = any, ParentType extends ResolversParentTypes['LogEventCursor'] = ResolversParentTypes['LogEventCursor']> = {
  id?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  validFrom?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type LogEventMetaResolvers<ContextType = any, ParentType extends ResolversParentTypes['LogEventMeta'] = ResolversParentTypes['LogEventMeta']> = {
  cursor?: Resolver<Maybe<ResolversTypes['LogEventCursor']>, ParentType, ContextType>;
  hasMore?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type MoneyResolvers<ContextType = any, ParentType extends ResolversParentTypes['Money'] = ResolversParentTypes['Money']> = {
  amount?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  currency?: Resolver<Maybe<ResolversTypes['Currency']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type MutationResolvers<ContextType = any, ParentType extends ResolversParentTypes['Mutation'] = ResolversParentTypes['Mutation']> = {
  createEmployee?: Resolver<Maybe<ResolversTypes['Employee']>, ParentType, ContextType, RequireFields<MutationCreateEmployeeArgs, 'input'>>;
  createTrigger?: Resolver<Maybe<ResolversTypes['TriggerSettings']>, ParentType, ContextType, RequireFields<MutationCreateTriggerArgs, 'input'>>;
  deleteTrigger?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType, RequireFields<MutationDeleteTriggerArgs, 'id'>>;
  triggerAllEmployeesHook?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType, Partial<MutationTriggerAllEmployeesHookArgs>>;
  triggerEmployeeHook?: Resolver<Maybe<ResolversTypes['Employee']>, ParentType, ContextType, RequireFields<MutationTriggerEmployeeHookArgs, 'id'>>;
  updateEmployee?: Resolver<Maybe<ResolversTypes['Employee']>, ParentType, ContextType, RequireFields<MutationUpdateEmployeeArgs, 'id' | 'input'>>;
  updateTool?: Resolver<Maybe<ResolversTypes['Tool']>, ParentType, ContextType, RequireFields<MutationUpdateToolArgs, 'id' | 'input'>>;
  updateTrigger?: Resolver<Maybe<ResolversTypes['TriggerSettings']>, ParentType, ContextType, RequireFields<MutationUpdateTriggerArgs, 'id' | 'input'>>;
};

export type OrganisationDataResolvers<ContextType = any, ParentType extends ResolversParentTypes['OrganisationData'] = ResolversParentTypes['OrganisationData']> = {
  billable?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  city?: Resolver<Maybe<ResolversTypes['City']>, ParentType, ContextType>;
  costCenter?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  country?: Resolver<Maybe<ResolversTypes['Country']>, ParentType, ContextType>;
  employedBy?: Resolver<Maybe<ResolversTypes['LegalEntity']>, ParentType, ContextType>;
  employedByPrettyName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  jobRole?: Resolver<Maybe<ResolversTypes['JOBROLE']>, ParentType, ContextType>;
  legalEntity?: Resolver<Maybe<ResolversTypes['LegalEntity']>, ParentType, ContextType>;
  legalEntityPrettyName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  organisation?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  organisationSettings?: Resolver<ResolversTypes['OrganisationSettings'], ParentType, ContextType>;
  seniority?: Resolver<Maybe<ResolversTypes['SENIORITY']>, ParentType, ContextType>;
  skillTrack?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  title?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  track?: Resolver<Maybe<ResolversTypes['TRACK']>, ParentType, ContextType>;
  trackPrettyName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type OrganisationSettingsResolvers<ContextType = any, ParentType extends ResolversParentTypes['OrganisationSettings'] = ResolversParentTypes['OrganisationSettings']> = {
  companyCode?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  fteHours?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PaginatedLogEventsResolvers<ContextType = any, ParentType extends ResolversParentTypes['PaginatedLogEvents'] = ResolversParentTypes['PaginatedLogEvents']> = {
  data?: Resolver<Array<ResolversTypes['LogEvent']>, ParentType, ContextType>;
  meta?: Resolver<ResolversTypes['LogEventMeta'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PermissionResolvers<ContextType = any, ParentType extends ResolversParentTypes['Permission'] = ResolversParentTypes['Permission']> = {
  read?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  write?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PermissionsResolvers<ContextType = any, ParentType extends ResolversParentTypes['Permissions'] = ResolversParentTypes['Permissions']> = {
  employee?: Resolver<Maybe<ResolversTypes['EmployeePermission']>, ParentType, ContextType, Partial<PermissionsEmployeeArgs>>;
  tool?: Resolver<Maybe<ResolversTypes['ToolPermission']>, ParentType, ContextType, Partial<PermissionsToolArgs>>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PrivacyResolvers<ContextType = any, ParentType extends ResolversParentTypes['Privacy'] = ResolversParentTypes['Privacy']> = {
  employee?: Resolver<Maybe<ResolversTypes['PrivacyEmployee']>, ParentType, ContextType>;
  public?: Resolver<Maybe<ResolversTypes['PrivacyPublic']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PrivacyEmployeeResolvers<ContextType = any, ParentType extends ResolversParentTypes['PrivacyEmployee'] = ResolversParentTypes['PrivacyEmployee']> = {
  address?: Resolver<Maybe<ResolversTypes['FieldReadPermission']>, ParentType, ContextType>;
  phonePersonal?: Resolver<Maybe<ResolversTypes['FieldReadPermission']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PrivacyPublicResolvers<ContextType = any, ParentType extends ResolversParentTypes['PrivacyPublic'] = ResolversParentTypes['PrivacyPublic']> = {
  phonePersonal?: Resolver<Maybe<ResolversTypes['FieldReadPermission']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ProjectResolvers<ContextType = any, ParentType extends ResolversParentTypes['Project'] = ResolversParentTypes['Project']> = {
  _id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  client?: Resolver<ResolversTypes['Client'], ParentType, ContextType>;
  createdBy?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  creationDate?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  description?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  number?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  responsibleEmployee?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type QueryResolvers<ContextType = any, ParentType extends ResolversParentTypes['Query'] = ResolversParentTypes['Query']> = {
  auditLogEvents?: Resolver<ResolversTypes['PaginatedLogEvents'], ParentType, ContextType, Partial<QueryAuditLogEventsArgs>>;
  client?: Resolver<Maybe<ResolversTypes['Client']>, ParentType, ContextType, Partial<QueryClientArgs>>;
  clients?: Resolver<Maybe<Array<Maybe<ResolversTypes['Client']>>>, ParentType, ContextType>;
  employee?: Resolver<Maybe<ResolversTypes['Employee']>, ParentType, ContextType, Partial<QueryEmployeeArgs>>;
  employees?: Resolver<Array<ResolversTypes['Employee']>, ParentType, ContextType, Partial<QueryEmployeesArgs>>;
  logEvents?: Resolver<Array<Maybe<ResolversTypes['LogEvent']>>, ParentType, ContextType, Partial<QueryLogEventsArgs>>;
  permissions?: Resolver<Maybe<ResolversTypes['Permissions']>, ParentType, ContextType>;
  possibleSkippedLogEvents?: Resolver<Array<Maybe<ResolversTypes['LogEvent']>>, ParentType, ContextType, Partial<QueryPossibleSkippedLogEventsArgs>>;
  project?: Resolver<Maybe<ResolversTypes['Project']>, ParentType, ContextType, Partial<QueryProjectArgs>>;
  projects?: Resolver<Maybe<Array<Maybe<ResolversTypes['Project']>>>, ParentType, ContextType>;
  snapshot?: Resolver<Array<ResolversTypes['Employee']>, ParentType, ContextType, Partial<QuerySnapshotArgs>>;
  tool?: Resolver<Maybe<ResolversTypes['Tool']>, ParentType, ContextType, Partial<QueryToolArgs>>;
  tools?: Resolver<Maybe<Array<Maybe<ResolversTypes['Tool']>>>, ParentType, ContextType>;
  trigger?: Resolver<Maybe<ResolversTypes['TriggerSettings']>, ParentType, ContextType, Partial<QueryTriggerArgs>>;
  triggerLogs?: Resolver<Maybe<Array<Maybe<ResolversTypes['TriggerLog']>>>, ParentType, ContextType>;
  triggers?: Resolver<Maybe<Array<Maybe<ResolversTypes['TriggerSettings']>>>, ParentType, ContextType>;
};

export type RelationResolvers<ContextType = any, ParentType extends ResolversParentTypes['Relation'] = ResolversParentTypes['Relation']> = {
  contacts?: Resolver<Array<ResolversTypes['Contact']>, ParentType, ContextType>;
  firstName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  lastName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  relation?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type SalaryAndBenefitsResolvers<ContextType = any, ParentType extends ResolversParentTypes['SalaryAndBenefits'] = ResolversParentTypes['SalaryAndBenefits']> = {
  actualSalary?: Resolver<ResolversTypes['Money'], ParentType, ContextType>;
  basicSalary?: Resolver<ResolversTypes['Money'], ParentType, ContextType>;
  vacationEntitlement?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type SlackActionConfigResolvers<ContextType = any, ParentType extends ResolversParentTypes['SlackActionConfig'] = ResolversParentTypes['SlackActionConfig']> = {
  recipients?: Resolver<Array<ResolversTypes['SlackRecipient']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type SlackRecipientResolvers<ContextType = any, ParentType extends ResolversParentTypes['SlackRecipient'] = ResolversParentTypes['SlackRecipient']> = {
  employeeId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  slackId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type SlackUserResolvers<ContextType = any, ParentType extends ResolversParentTypes['SlackUser'] = ResolversParentTypes['SlackUser']> = {
  id?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  image_72?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  image_192?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  username?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ToolResolvers<ContextType = any, ParentType extends ResolversParentTypes['Tool'] = ResolversParentTypes['Tool']> = {
  _id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  github?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  roles?: Resolver<Array<ResolversTypes['String']>, ParentType, ContextType>;
  scopes?: Resolver<Array<ResolversTypes['String']>, ParentType, ContextType>;
  token?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  url?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ToolPermissionResolvers<ContextType = any, ParentType extends ResolversParentTypes['ToolPermission'] = ResolversParentTypes['ToolPermission']> = {
  _id?: Resolver<Maybe<ResolversTypes['Permission']>, ParentType, ContextType>;
  github?: Resolver<Maybe<ResolversTypes['Permission']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['Permission']>, ParentType, ContextType>;
  name?: Resolver<Maybe<ResolversTypes['Permission']>, ParentType, ContextType>;
  roles?: Resolver<Maybe<ResolversTypes['Permission']>, ParentType, ContextType>;
  scopes?: Resolver<Maybe<ResolversTypes['Permission']>, ParentType, ContextType>;
  token?: Resolver<Maybe<ResolversTypes['Permission']>, ParentType, ContextType>;
  url?: Resolver<Maybe<ResolversTypes['Permission']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TriggerActionResolvers<ContextType = any, ParentType extends ResolversParentTypes['TriggerAction'] = ResolversParentTypes['TriggerAction']> = {
  body?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  config?: Resolver<ResolversTypes['SlackActionConfig'], ParentType, ContextType>;
  type?: Resolver<ResolversTypes['TriggerActionType'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TriggerActionPerformedLogResolvers<ContextType = any, ParentType extends ResolversParentTypes['TriggerActionPerformedLog'] = ResolversParentTypes['TriggerActionPerformedLog']> = {
  message?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  recipients?: Resolver<Array<ResolversTypes['String']>, ParentType, ContextType>;
  type?: Resolver<Maybe<ResolversTypes['TriggerActionType']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TriggerCriteriaResolvers<ContextType = any, ParentType extends ResolversParentTypes['TriggerCriteria'] = ResolversParentTypes['TriggerCriteria']> = {
  path?: Resolver<Array<ResolversTypes['String']>, ParentType, ContextType>;
  target?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  type?: Resolver<ResolversTypes['TriggerCriteriaComparatorType'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TriggerInputResolvers<ContextType = any, ParentType extends ResolversParentTypes['TriggerInput'] = ResolversParentTypes['TriggerInput']> = {
  type?: Resolver<ResolversTypes['TriggerInputType'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TriggerLogResolvers<ContextType = any, ParentType extends ResolversParentTypes['TriggerLog'] = ResolversParentTypes['TriggerLog']> = {
  actionsPerformed?: Resolver<Array<ResolversTypes['TriggerActionPerformedLog']>, ParentType, ContextType>;
  employeeCount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  timestamp?: Resolver<ResolversTypes['GraphQLDateTime'], ParentType, ContextType>;
  trigger?: Resolver<ResolversTypes['TriggerSettings'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TriggerLogInputResolvers<ContextType = any, ParentType extends ResolversParentTypes['TriggerLogInput'] = ResolversParentTypes['TriggerLogInput']> = {
  actionsPerformed?: Resolver<Array<ResolversTypes['TriggerActionPerformedLog']>, ParentType, ContextType>;
  employeeCount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  timestamp?: Resolver<ResolversTypes['GraphQLDateTime'], ParentType, ContextType>;
  trigger?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TriggerSettingsResolvers<ContextType = any, ParentType extends ResolversParentTypes['TriggerSettings'] = ResolversParentTypes['TriggerSettings']> = {
  _id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  actions?: Resolver<Array<ResolversTypes['TriggerAction']>, ParentType, ContextType>;
  criteria?: Resolver<Array<ResolversTypes['TriggerCriteria']>, ParentType, ContextType>;
  description?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  filters?: Resolver<Array<ResolversTypes['TriggerCriteria']>, ParentType, ContextType>;
  input?: Resolver<ResolversTypes['TriggerInput'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Resolvers<ContextType = any> = {
  Absence?: AbsenceResolvers<ContextType>;
  Address?: AddressResolvers<ContextType>;
  ChangedBy?: ChangedByResolvers<ContextType>;
  Client?: ClientResolvers<ContextType>;
  Company?: CompanyResolvers<ContextType>;
  Contact?: ContactResolvers<ContextType>;
  Contract?: ContractResolvers<ContextType>;
  ContractEvent?: ContractEventResolvers<ContextType>;
  Date?: GraphQLScalarType;
  Employee?: EmployeeResolvers<ContextType>;
  EmployeePermission?: EmployeePermissionResolvers<ContextType>;
  FieldPermission?: FieldPermissionResolvers<ContextType>;
  FieldReadPermission?: FieldReadPermissionResolvers<ContextType>;
  GraphQLDateTime?: GraphQLScalarType;
  Identity?: IdentityResolvers<ContextType>;
  Integration?: IntegrationResolvers<ContextType>;
  JSON?: GraphQLScalarType;
  LogEvent?: LogEventResolvers<ContextType>;
  LogEventCursor?: LogEventCursorResolvers<ContextType>;
  LogEventMeta?: LogEventMetaResolvers<ContextType>;
  Money?: MoneyResolvers<ContextType>;
  Mutation?: MutationResolvers<ContextType>;
  OrganisationData?: OrganisationDataResolvers<ContextType>;
  OrganisationSettings?: OrganisationSettingsResolvers<ContextType>;
  PaginatedLogEvents?: PaginatedLogEventsResolvers<ContextType>;
  Permission?: PermissionResolvers<ContextType>;
  Permissions?: PermissionsResolvers<ContextType>;
  Privacy?: PrivacyResolvers<ContextType>;
  PrivacyEmployee?: PrivacyEmployeeResolvers<ContextType>;
  PrivacyPublic?: PrivacyPublicResolvers<ContextType>;
  Project?: ProjectResolvers<ContextType>;
  Query?: QueryResolvers<ContextType>;
  Relation?: RelationResolvers<ContextType>;
  SalaryAndBenefits?: SalaryAndBenefitsResolvers<ContextType>;
  SlackActionConfig?: SlackActionConfigResolvers<ContextType>;
  SlackRecipient?: SlackRecipientResolvers<ContextType>;
  SlackUser?: SlackUserResolvers<ContextType>;
  Tool?: ToolResolvers<ContextType>;
  ToolPermission?: ToolPermissionResolvers<ContextType>;
  TriggerAction?: TriggerActionResolvers<ContextType>;
  TriggerActionPerformedLog?: TriggerActionPerformedLogResolvers<ContextType>;
  TriggerCriteria?: TriggerCriteriaResolvers<ContextType>;
  TriggerInput?: TriggerInputResolvers<ContextType>;
  TriggerLog?: TriggerLogResolvers<ContextType>;
  TriggerLogInput?: TriggerLogInputResolvers<ContextType>;
  TriggerSettings?: TriggerSettingsResolvers<ContextType>;
};

